import React, { useEffect, useState, ChangeEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFileExtensionList, getFileList, getLastModifiedList, getOpportunitiesList, getTagList } from './fileExplorer.slice'
import Table from '../../components/Table/Table'
import { File, Tag } from '../../apis/types'
import { formatFileSize, formatDateTime } from '../../util/string'
import { isTaggedFile, extractTag } from '../../util/tags'
import { pushToast } from '../../components/Toaster/Toaster.slice'
import TagBadge from './components/TagBadge'
import TableCheckMark from './components/TableCheckMark'
import BulkActions from './components/BulkActions'
import Panel from '../../components/Panel'
import ComboboxAutocomplete from '../../components/Form/ComboboxAutocomplete'
import Datepicker from 'react-tailwindcss-datepicker'
import classnames from 'classnames'
import TagPopover from './components/TagPopover'
import { deleteTagForFileApi, addTagToFileApi, renameFileApi, getOpportunityFolderIdApi, downloadFileApi } from '../../apis/apis'
import InlineEditInput from '../../components/Form/InlineEditInput'
import * as Yup from 'yup'
import { FileIcon } from 'react-file-icon'
import GoogleDocIcon from './assets/file_icons/icon-google-doc'
import GoogleSlidesIcon from './assets/file_icons/icon-google-slides'
import GoogleSheetIcon from './assets/file_icons/icon-google-sheet'
import GoogleFormIcon from './assets/file_icons/icon-google-form'
import { Tooltip } from 'flowbite-react'
import { fileExtensionIconStyles } from './styles/FileExtensionIconStyles'
import ActionsPopover from './components/ActionsPopover'
import { FilterUndoButton } from './components/FilterUndoButton'
import ActionButtonWithIcon from './components/ActionButtonWithIcon'
import ControlledQuickSearch from '../../components/QuickSearch/ControlledQuickSearch'
import { format, parseISO, addDays } from 'date-fns'
import { useAppDispatch, useAppSelector } from '../../shared/redux/hooks'
import { getInputStyles } from '../../components/Form/styles'
// Add type definition
type FileIconComponent = React.ComponentType<any>
const FileIconWithTypes = FileIcon as FileIconComponent

const FileExplorer: React.FC = () => {
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  const { files, fileExtensions, lastModifiedList, opportunitiesList, tagsList, loading, error } = useAppSelector((state) => state.fileExplorer)
  const { permissions } = useAppSelector((state) => state.app)
  const navigate = useNavigate()

  // Disable dark mode for Datepicker component
  localStorage.theme = 'light'

  // Google Drive constants
  const GOOGLE_DRIVE_FOLDER_LINK_HEADER: string = 'https://drive.google.com/drive/folders/'
  const GOOGLE_DRIVE_FILE_LINK_HEADER: string = 'https://drive.google.com/file/d/'
  const GOOGLE_DOCUMENTS_MIME_TYPES: Map<string, string> = new Map([
    ['application/vnd.google-apps.document', 'Google Docs'],
    ['application/vnd.google-apps.spreadsheet', 'Google Sheets'],
    ['application/vnd.google-apps.presentation', 'Google Slides'],
    ['application/vnd.google-apps.form', 'Google Forms'],
  ])

  const [tagsListForCombobox, setTagsListForCombobox] = useState<Array<{ id: string; name: string; description: string | undefined }>>([])

  // State handling for checkboxes
  const [headerCheckStatus, setHeaderCheckStatus] = useState<boolean>(false)
  const [filesChecked, setFilesChecked] = useState<Map<string, string>>(new Map())
  const [numRowsChecked, setNumRowsChecked] = useState<number>(Array.from(filesChecked.values()).length)

  // Variables for filter/search term/sorting variables
  // Some filters are based on state variables, some are based on URL params, and some are based on both
  // This is done on a case by case basis
  const searchParams = new URLSearchParams(search)
  const [quickSearchTerm, setQuickSearchTerm] = useState<string | undefined>()
  const fileExtension = searchParams.get('fileExtension')
  const fileType = searchParams.get('fileType')
  const lastModifiedPermissionId = searchParams.get('lastModifiedPermissionId')
  const opportunity = searchParams.get('opportunity')
  const [tag, setTag] = useState<string | null>(searchParams.get('tag') ?? null)
  const [datePickerValue, setDatePickerValue] = useState({
    startDate: null,
    endDate: null,
  })
  // Stores the appropriate URL param for sorting
  const sortColumn = searchParams.get('sortColumn')
  const sortDirection = searchParams.get('sortDirection')
  // Stores whether each column is being sorted by or not
  const [sortingStatus, setSortingStatus] = useState<Map<string, string | null>>(() => {
    const defaultSortingStatus = new Map([
      ['fileName', sortColumn === 'fileName' ? sortDirection : 'NONE'],
      ['filePath', sortColumn === 'filePath' ? sortDirection : 'NONE'],
      ['fileExtension', sortColumn === 'fileExtension' ? sortDirection : 'NONE'],
      ['fileSize', sortColumn === 'fileSize' ? sortDirection : 'NONE'],
      ['lastModifiedName', sortColumn === 'lastModifiedName' ? sortDirection : 'NONE'],
      ['lastModifiedDate', sortColumn === 'lastModifiedDate' ? sortDirection : 'NONE'],
      ['tag', sortColumn === 'tag' ? sortDirection : 'NONE'],
    ])

    if (sortColumn == null || sortDirection == null) {
      defaultSortingStatus.set('lastModifiedDate', 'DESC')
    }

    return defaultSortingStatus
  })
  const parentFileId = searchParams.get('parentFileId')
  const [workflow, setWorkflow] = useState<string | null>(searchParams.get('workflow'))

  // State handling for handling the URL params, which are used to fetch the file list
  // sortColumn and sortDirection are set to default values if they are not present in the URL
  const [currentUrlParams, setCurrentUrlParams] = useState(() => {
    const params = searchParams
    if (!params.has('sortColumn')) {
      params.set('sortColumn', 'lastModifiedDate')
    }
    if (!params.has('sortDirection')) {
      params.set('sortDirection', 'DESC')
    }
    if (!params.has('workflow')) {
      params.set('workflow', 'Default')
    }
    return params
  })

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [paginatorPage, setPaginatorPage] = useState<number>(0)

  // Check if the user has permissions to tag/rename files
  const hasTaggingPermission = (): boolean => (permissions != null ? permissions.includes('storagedrive:file:tag') : false)
  const hasRenamingPermission = (): boolean => (permissions != null ? permissions.includes('storagedrive:file:rename') : false)

  const fileMimeTypeFromURLParam = (): string | undefined => {
    let fileMimeType

    if (fileType != null) {
      const fileTypeWithoutPlus = fileType.replace('+', '')
      const foundEntry = Array.from(GOOGLE_DOCUMENTS_MIME_TYPES.entries()).find(([_mimeType, label]) => label === fileTypeWithoutPlus)
      if (foundEntry != null) {
        fileMimeType = foundEntry[0]
      }
    }
    return fileMimeType
  }

  const fetchFiles = (page: number): void => {
    setIsLoading(true)
    const fileMimeType = fileMimeTypeFromURLParam()
    try {
      void dispatch(
        getFileList({
          page,
          resultsPerPage: 50,
          fileName: quickSearchTerm === '' ? undefined : quickSearchTerm,
          fileExtension: fileExtension,
          fileMimeType: fileMimeType != null ? encodeURIComponent(fileMimeType) : undefined,
          lastModifiedPermissionId: lastModifiedPermissionId,
          // Format dates in UTC then covert to milliseconds past epoch
          // Also ensure date range is the dates in the user's timezone inclusive
          startDate: datePickerValue.startDate !== null ? new Date(parseISO(datePickerValue.startDate)).getTime() : null,
          endDate: datePickerValue.endDate !== null ? addDays(new Date(parseISO(datePickerValue.endDate)), 1).getTime() : null,
          tag: tag,
          opportunity: opportunity,
          // set sort column to be the only column in sortStatus that has a value of 'ASC' or 'DESC'
          sortColumn: Array.from(sortingStatus.entries()).find((entry) => entry[1] === 'ASC' || entry[1] === 'DESC')?.[0],
          sortDirection: Array.from(sortingStatus.entries()).find((entry) => entry[1] === 'ASC' || entry[1] === 'DESC')?.[1],
          parentFileId,
          role: workflow === 'None' || workflow === 'Default' ? undefined : workflow?.toLowerCase(),
        })
      )
    } catch (e) {
      pushToast({
        type: 'error',
        message: 'Error fetching file list',
        description: `${(e as XMLHttpRequest).response?.data?.error !== undefined ? `${(e as XMLHttpRequest).response?.data?.error}:` : 'Error:'} ${
          (e as { message: string }).message
        }`,
      })
    } finally {
      setIsLoading(false)
    }
  }

  // Each time any of the search terms change, fetch the file list but don't fetch it on first render
  const [hasRendered, setHasRendered] = useState(false)

  useEffect(() => {
    if (!hasRendered) {
      setHasRendered(true)
      return
    }

    fetchFiles(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quickSearchTerm,
    fileExtension,
    lastModifiedPermissionId,
    datePickerValue,
    opportunity,
    tag,
    sortColumn,
    sortDirection,
    parentFileId,
    hasRendered,
    fileType,
  ])

  useEffect(() => {
    fileExtensions?.length === 0 && dispatch(getFileExtensionList())
    lastModifiedList?.length === 0 && dispatch(getLastModifiedList())
    opportunitiesList?.length === 0 && dispatch(getOpportunitiesList())
    tagsList?.length === 0 && dispatch(getTagList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  // Each time a parameter is added or removed from current UrlParams, update the url
  useEffect(() => {
    navigate(
      {
        search: currentUrlParams.toString(),
      },
      { replace: true }
    )
  }, [currentUrlParams, history])

  // On first render, replace the url with the current url params (will be set to default values)
  useEffect(() => {
    navigate(
      {
        search: currentUrlParams.toString(),
      },
      { replace: true }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Commented out until duplicate directories in GDrive are figured out

  // useEffect(() => {
  //   void dispatch(getDirectoriesList({ opportunityId: opportunity !== null ? opportunity : null }))
  // }, [dispatch, opportunity])

  useEffect(() => {
    if (tagsList != null) {
      const newTagsListForCombobox = tagsList.map((tag) => ({
        id: tag.keyword,
        name: tag.keyword,
        description: tag.item,
      }))
      setTagsListForCombobox(newTagsListForCombobox)
    }
  }, [tagsList])
  // Each time the a new page is selected, fetch the file list
  const handlePaginatorClick = (page: number): void => {
    fetchFiles(page)
    setPaginatorPage(page)
  }

  const setDefaultWorkflowURLSearchParams = (): URLSearchParams => {
    // Update state variables
    setWorkflow('Default')
    setTag(null)

    // Set new URL params
    const newUrlParams = new URLSearchParams()
    newUrlParams.set('sortColumn', 'lastModifiedDate')
    newUrlParams.set('sortDirection', 'DESC')
    newUrlParams.set('workflow', 'Default')

    // Set sorting status to default
    const newSortingStatus = new Map(sortingStatus)
    newSortingStatus.forEach((_value, key) => {
      newSortingStatus.set(key, 'none')
    })
    newSortingStatus.set('lastModifiedDate', 'DESC')
    setSortingStatus(newSortingStatus)

    handleSelectDateRange({ startDate: null, endDate: null })
    return newUrlParams
  }
  const setTaggerWorkflowURLSearchParams = (): URLSearchParams => {
    // Update state variables
    setWorkflow('Tagger')
    setTag('untagged')

    // Set new URL params
    const newUrlParams = new URLSearchParams()
    newUrlParams.set('sortColumn', 'lastModifiedDate')
    newUrlParams.set('sortDirection', 'DESC')
    newUrlParams.set('workflow', 'Tagger')
    newUrlParams.set('tag', 'untagged')
    // Set current day to today
    handleSelectDateRange({
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
    })
    newUrlParams.set('startDate', format(new Date(), 'yyyy-MM-dd'))
    newUrlParams.set('endDate', format(new Date(), 'yyyy-MM-dd'))

    // Set sorting status to default
    const newSortingStatus = new Map(sortingStatus)
    newSortingStatus.forEach((_value, key) => {
      newSortingStatus.set(key, 'none')
    })
    newSortingStatus.set('lastModifiedDate', 'DESC')
    setSortingStatus(newSortingStatus)

    return newUrlParams
  }

  // Handle selecting a workflow via the workflow dropdown
  // Passed to the WorkflowSelect component
  const handleSelectWorkflow = (newWorkflow: string): void => {
    let newUrlParams: URLSearchParams = new URLSearchParams(currentUrlParams.toString())
    if (currentUrlParams.has('workflow')) {
      newUrlParams.delete('workflow')
    }

    if (newWorkflow === 'Default') {
      newUrlParams = setDefaultWorkflowURLSearchParams()
    } else if (newWorkflow === 'Tagger') {
      newUrlParams = setTaggerWorkflowURLSearchParams()
    }

    setCurrentUrlParams(newUrlParams)
  }

  // This function is called each time a new filter is selected or deselected to set the workflow to 'None' via the url params
  const setWorkFlowURLSearchParamsOnChange = (urlParams: URLSearchParams, workflow: string): URLSearchParams => {
    const newUrlParams = new URLSearchParams(urlParams.toString())
    if (urlParams.has('workflow')) {
      newUrlParams.delete('workflow')
    }
    newUrlParams.set('workflow', workflow ?? '')
    return newUrlParams
  }

  const handleSort = (columnValue: string, sortDirection: string): void => {
    // Update the sorting status map based on the column that was clicked
    const newSortingStatus = new Map(sortingStatus)
    newSortingStatus.forEach((_value, key) => {
      newSortingStatus.set(key, 'none')
    })
    newSortingStatus.set(columnValue, sortDirection)
    setSortingStatus(newSortingStatus)

    // Update the URL params
    const newUrlParams = new URLSearchParams(currentUrlParams.toString())
    if (currentUrlParams.has('sortColumn') && currentUrlParams.has('sortDirection') && (sortDirection === 'none' || sortDirection === 'NONE')) {
      newUrlParams.delete('sortColumn')
      newUrlParams.delete('sortDirection')
    } else {
      let sortColumnURLParam
      let sortDirectionURLParam
      newSortingStatus.forEach((value, key) => {
        if (value !== 'none') {
          sortColumnURLParam = key
          sortDirectionURLParam = value?.toUpperCase()
        }
      })

      newUrlParams.set('sortColumn', sortColumnURLParam ?? '')
      newUrlParams.set('sortDirection', sortDirectionURLParam ?? '')
    }
    const finalUrlParams = setWorkFlowURLSearchParamsOnChange(newUrlParams, 'None')
    setCurrentUrlParams(finalUrlParams)
  }

  const handleAddTag = async (fileIdsAndNamesToTag: Map<string, string>, tagKeyword: string): Promise<void> => {
    try {
      const fileIds = Array.from(fileIdsAndNamesToTag.keys())
      const tagToAdd: Tag | undefined = tagsList?.find((t) => t.keyword === tagKeyword)
      await addTagToFileApi(fileIds, tagToAdd as Tag)
      await dispatch(
        pushToast({
          type: 'success',
          message: 'Tag added',
        })
      )
      fetchFiles(paginatorPage)
    } catch (e) {
      await dispatch(
        pushToast({
          type: 'error',
          message: 'Error adding tag',
          description: `${(e as XMLHttpRequest).response?.data?.error !== undefined ? `${(e as XMLHttpRequest).response?.data?.error}:` : 'Error:'} ${
            (e as { message: string }).message
          }`,
        })
      )
    }
  }

  const handleDeleteTag = async (fileIdsAndNamesToUntag: Map<string, string>): Promise<void> => {
    try {
      await deleteTagForFileApi(Array.from(fileIdsAndNamesToUntag.keys()))
      await dispatch(
        pushToast({
          type: 'success',
          message: 'Tag deleted',
        })
      )
      fetchFiles(paginatorPage)
    } catch (e) {
      await dispatch(
        pushToast({
          type: 'error',
          message: 'Error deleting tag',
          description: `${
            (e as XMLHttpRequest).response?.data?.message !== undefined ? `${(e as XMLHttpRequest).response?.data?.message as string}:` : 'Error:'
          } ${(e as { message: string }).message}`,
        })
      )
    }
  }

  const handleHeaderCheckMarkClick = (): void => {
    // Set all rows to the status of the header
    setHeaderCheckStatus(!headerCheckStatus)

    const newFilesChecked: Map<string, string> = new Map(filesChecked)
    if (headerCheckStatus) {
      files?.results?.forEach((file) => {
        newFilesChecked.delete(file.fileId)
      })
    } else {
      files?.results?.forEach((file) => {
        newFilesChecked.set(file.fileId, file.fileName)
      })
    }

    setFilesChecked(newFilesChecked)
    setNumRowsChecked(newFilesChecked.size)
  }

  const handleCheckMarkClick = (fileId: string, fileName: string): void => {
    // Check if the file is already checked
    const currentFileCheckStatus = filesChecked.has(fileId)

    const newFilesChecked = new Map(filesChecked)

    if (currentFileCheckStatus) {
      // If the file was checked, uncheck it by removing it from the map
      newFilesChecked.delete(fileId)
    } else {
      // If the file was unchecked, check it by adding it to the map
      newFilesChecked.set(fileId, fileName)
    }
    setFilesChecked(newFilesChecked)
    setNumRowsChecked(newFilesChecked.size)

    // If unchecking a file, uncheck the header because now not all files are checked
    if (currentFileCheckStatus) {
      setHeaderCheckStatus(false)
    }
    // If checking a file, check the header if all files are checked
    else {
      let allFilesChecked = true
      files?.results?.forEach((file) => {
        if (!newFilesChecked.has(file.cuid)) {
          allFilesChecked = false
        }
      })
      setHeaderCheckStatus(allFilesChecked)
    }
  }

  const handleRename = async (fileId: string, newName: string): Promise<void> => {
    // Note: error handling and toasts handled in InlineEditInput component's handleSubmit function
    await renameFileApi(fileId, newName)
    fetchFiles(paginatorPage)
  }

  // Download the selected files by creating a zip file, creating link to download it, and programmatically clicking the link, then removing the link
  const handleDownload = async (
    filesToDownload: Array<{
      id: string
      name: string
      opportunity: string | undefined
      mimeType: string | undefined
      extension: string | undefined
    }>
  ): Promise<void> => {
    try {
      if (filesToDownload == null) {
        throw new Error('File(s) do not have an id in google drive')
      }
      const fileIds = filesToDownload.map((file) => file.id)
      if (fileIds.length === 0) {
        throw new Error('No files selected')
      }

      await dispatch(
        pushToast({
          message: 'File(s) downloading ...',
        })
      )

      let fileMimeType: string | undefined
      if (filesToDownload.length === 1) fileMimeType = filesToDownload[0].mimeType

      const rawFileFromApi = await downloadFileApi(fileIds)

      // Create a Blob from the rawFileFromApi
      const blob = new Blob([rawFileFromApi], {
        type: fileMimeType ?? 'application/zip',
      })

      // Create an object URL for the Blob
      const url = URL.createObjectURL(blob)

      // Create a link element
      const link = document.createElement('a')

      // Generate the file name
      // Get readable timestamp with format yyyy-MM-dd_hh_mm_ss
      const timestamp = format(new Date(), 'yyyy-MM-dd_hh_mm_ss')
      let fileName: string = `${timestamp}_filesFromFileExplorer`
      if (filesToDownload.length === 1) {
        fileName = `${filesToDownload[0].name}`
      } else {
        // Check if all files share one opportunity
        // If they do add the shortened opportunity name without the unique id to the file name
        const filesOpportunity = filesToDownload.map((file) => file.opportunity)
        const uniqueOpportunities = Array.from(new Set(filesOpportunity))
        if (uniqueOpportunities[0] != null && uniqueOpportunities.length === 1) {
          const fullOppName = uniqueOpportunities[0]
          const oppNameWithoutId = fullOppName.slice(0, fullOppName.lastIndexOf('-'))
          fileName = `${timestamp}_${oppNameWithoutId}_filesFromFileExplorer`
        }
      }

      // Set the href and download attributes of the link
      link.href = url
      link.download = fileName

      // Append the link to the body
      document.body.appendChild(link)

      // Programmatically click the link
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)

      await dispatch(
        pushToast({
          type: 'success',
          message: 'File(s) downloaded',
        })
      )
    } catch (e) {
      await dispatch(
        pushToast({
          type: 'error',
          message: 'Error downloading file(s)',
          description: `${(e as XMLHttpRequest).response?.data?.error !== undefined ? `${(e as XMLHttpRequest).response?.data?.error}:` : 'Error:'} ${
            (e as { message: string }).message
          }`,
        })
      )
    }
  }

  // Fetch an opportunity GDrive folder's unique id from an opportunity id
  const getOpportunityFolderId = async (opportunityId: string | null | undefined): Promise<string> => {
    if (opportunityId == null) {
      throw new Error('File does not have a corresponding opportunity')
    }

    try {
      const response = await getOpportunityFolderIdApi(opportunityId)
      return response
    } catch (e) {
      pushToast({
        type: 'error',
        message: 'Error fetching file opportunity folder id',
        description: `${(e as XMLHttpRequest).response?.data?.error !== undefined ? `${(e as XMLHttpRequest).response?.data?.error}:` : 'Error:'} ${
          (e as { message: string }).message
        }`,
      })
      return ''
    }
  }

  // Functions for handling filter changes
  const handleQuickSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === '') {
      setQuickSearchTerm(undefined)
      return
    }
    setQuickSearchTerm(event.target.value)
  }

  // When the file extension combobox changes, update the url params for either file type or file extension
  const handleSelectFileType = (fileType: string | undefined): void => {
    const newUrlParams = new URLSearchParams(currentUrlParams.toString())
    newUrlParams.delete('fileExtension')
    newUrlParams.delete('fileType')
    if (fileType != null && Array.from(GOOGLE_DOCUMENTS_MIME_TYPES.keys()).includes(fileType)) {
      newUrlParams.set('fileType', GOOGLE_DOCUMENTS_MIME_TYPES.get(fileType) ?? '')
    } else if (fileType != null) {
      newUrlParams.set('fileExtension', fileType ?? '')
    }
    const finalUrlParams = setWorkFlowURLSearchParamsOnChange(newUrlParams, 'None')
    setCurrentUrlParams(finalUrlParams)
  }

  // When the lastModifiedPermissionId changes, update the url params
  const handleSelectLastModified = (lastModifiedPermissionId: string | undefined): void => {
    const newUrlParams = new URLSearchParams(currentUrlParams.toString())
    if (currentUrlParams.has('lastModifiedPermissionId') && (lastModifiedPermissionId === '' || lastModifiedPermissionId === null)) {
      newUrlParams.delete('lastModifiedPermissionId')
    } else {
      newUrlParams.set('lastModifiedPermissionId', lastModifiedPermissionId ?? '')
    }
    const finalUrlParams = setWorkFlowURLSearchParamsOnChange(newUrlParams, 'None')
    setCurrentUrlParams(finalUrlParams)
  }

  const handleSelectOpportunity = (directory: string | undefined): void => {
    const newUrlParams = new URLSearchParams(currentUrlParams.toString())
    if (currentUrlParams.has('opportunity') && (directory === '' || directory === null)) {
      newUrlParams.delete('opportunity')
    } else {
      newUrlParams.set('opportunity', directory ?? '')
    }
    const finalUrlParams = setWorkFlowURLSearchParamsOnChange(newUrlParams, 'None')
    setCurrentUrlParams(finalUrlParams)
  }

  const handleSelectTag = (tag: string | undefined): void => {
    setTag(tag as string)
    const newUrlParams = new URLSearchParams(currentUrlParams.toString())
    if (currentUrlParams.has('tag') && (tag === '' || tag === null)) {
      newUrlParams.delete('tag')
    } else {
      newUrlParams.set('tag', tag ?? '')
    }
    const finalUrlParams = setWorkFlowURLSearchParamsOnChange(newUrlParams, 'None')
    setCurrentUrlParams(finalUrlParams)
  }

  const handleSelectDateRange = (newDateRange: any): void => {
    setDatePickerValue(newDateRange)
    const { startDate, endDate } = newDateRange

    const newUrlParams = new URLSearchParams(currentUrlParams.toString())

    if (currentUrlParams.has('startDate') && startDate === null) newUrlParams.delete('startDate')
    else newUrlParams.set('startDate', startDate)

    if (currentUrlParams.has('endDate') && endDate === null) newUrlParams.delete('endDate')
    else newUrlParams.set('endDate', endDate)

    const finalUrlParams = setWorkFlowURLSearchParamsOnChange(newUrlParams, 'None')
    setCurrentUrlParams(finalUrlParams)
  }

  const handleUndo = (filter: string): void => {
    if (filter === 'quickSearchTerm') setQuickSearchTerm('')
    else if (filter === 'dateRange') setDatePickerValue({ startDate: null, endDate: null })
    else {
      if (filter === 'tag') setTag(null)
      const newUrlParams = new URLSearchParams(currentUrlParams.toString())
      newUrlParams.delete(filter)
      setCurrentUrlParams(newUrlParams)
    }
  }

  const handleUndoAll = (): void => {
    setQuickSearchTerm('')
    setTag(null)
    setDatePickerValue({ startDate: null, endDate: null })
    const newUrlParams = new URLSearchParams()
    newUrlParams.set('workflow', 'None')
    setCurrentUrlParams(newUrlParams)
  }

  // Commented out until duplicate directories in GDrive are figured out

  // const handleSelectDirectory = (directory: string): void => {
  //   const newUrlParams = new URLSearchParams(currentUrlParams.toString())
  //   if (currentUrlParams.has('parentFileId') && (directory === '' || directory === null)) {
  //     newUrlParams.delete('parentFileId')
  //   } else {
  //     newUrlParams.set('parentFileId', directory ?? '')
  //   }
  //   setCurrentUrlParams(newUrlParams)
  // }

  if (error) return <div className="my-4 mx-4 dark:text-white">Error loading files, you may not have permission to access this page</div>
  if (loading && error == null) return <div className="my-4 mx-4 dark:text-white">Loading ...</div>
  return (
    // Custom layout for the file explorer page
    <main className="flex-1 relative focus:outline-none text-sm dark:text-white">
      <div className="py-6">
        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-4">
          <div className="flex flex-row justify-between items-center">
            <div className="mb-5 text-2xl font-medium mt-4 dark:text-white">File Explorer</div>
            <div className="flex flex-row justify-end items-center">
              <div className="flex flex-col w-[300px] mr-3">
                <ComboboxAutocomplete
                  handleChange={handleSelectWorkflow}
                  data={[
                    { id: 'Default', name: 'Default', description: 'All files, no filters' },
                    {
                      id: 'Tagger',
                      name: 'Tagger',
                      description: 'Unnecessary file types such as images filtered, untagged and last modified today filter applied',
                    },
                  ]}
                  value={workflow ?? 'Default'}
                  wildCardDisabled={true}
                />
              </div>
              <div className="mr-3">
                <ActionButtonWithIcon
                  onClick={() => {
                    handleUndoAll()
                  }}
                  icon="undo"
                  title="Reset all Filters"
                />
              </div>
              <div>
                <ActionButtonWithIcon
                  onClick={() => {
                    fetchFiles(paginatorPage)
                  }}
                  icon="refresh"
                  title="Refresh Files"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <Panel header="Filters" className="mb-5">
            <div className="grid grid-auto-flow grid-cols-3 gap-3 mb-2">
              <div className="flex flex-row">
                <ControlledQuickSearch
                  title="File Name"
                  handleChange={handleQuickSearchChange}
                  placeholder="Search for a file name..."
                  value={quickSearchTerm}
                />
                {quickSearchTerm != null && quickSearchTerm !== '' && <FilterUndoButton filter="quickSearchTerm" handleUndo={handleUndo} />}
              </div>
              <div className="flex flex-row w-full">
                <div className="w-full">
                  {fileExtensions !== undefined && (
                    <>
                      <ComboboxAutocomplete
                        label="File Type"
                        labelPlural="File Types"
                        value={fileExtension ?? fileMimeTypeFromURLParam() ?? 'All file types'}
                        handleChange={handleSelectFileType}
                        // Get the file extensions from the fileExtensions array and add the google docs mime types
                        data={fileExtensions
                          ?.map((fileExtension) => ({
                            id: fileExtension,
                            name: fileExtension,
                          }))
                          .concat(
                            Array.from(GOOGLE_DOCUMENTS_MIME_TYPES.entries()).map(([mimeType, label]) => ({
                              id: mimeType,
                              name: label,
                            }))
                          )}
                      />
                    </>
                  )}
                </div>
                {fileExtension != null && <FilterUndoButton filter="fileExtension" handleUndo={handleUndo} />}
                {fileType != null && <FilterUndoButton filter="fileType" handleUndo={handleUndo} />}
              </div>
              <div className="flex flex-row w-full">
                <div className="w-full">
                  {lastModifiedList !== undefined && (
                    <ComboboxAutocomplete
                      label="File Modifier"
                      labelPlural="File Modifiers"
                      value={lastModifiedPermissionId ?? 'All file modifiers'}
                      handleChange={handleSelectLastModified}
                      data={lastModifiedList?.map((lastModified) => ({
                        id: lastModified.id,
                        name: lastModified.name.split('@')[0],
                      }))}
                    />
                  )}
                </div>
                {lastModifiedPermissionId != null && lastModifiedList !== undefined && (
                  <FilterUndoButton filter="lastModifiedPermissionId" handleUndo={handleUndo} />
                )}
              </div>
            </div>
            <div className="grid grid-auto-flow grid-cols-3 gap-3 mb-2">
              <div className="flex flex-row w-full">
                <div className="w-full">
                  {opportunitiesList !== undefined && (
                    <ComboboxAutocomplete
                      label="Opportunity"
                      labelPlural="Opportunities"
                      value={opportunity ?? 'All opportunities'}
                      handleChange={handleSelectOpportunity}
                      data={opportunitiesList?.map((opportunity) => ({
                        id: opportunity.id,
                        name: opportunity.name,
                      }))}
                    />
                  )}
                </div>
                {opportunity != null && opportunitiesList !== undefined && <FilterUndoButton filter="opportunity" handleUndo={handleUndo} />}
              </div>
              <div className="flex flex-row w-full">
                <div className="w-full">
                  {tagsList !== undefined && (
                    <ComboboxAutocomplete
                      label="Tag"
                      labelPlural="Tagged and Untagged"
                      value={tag ?? 'All tagged and untagged'}
                      handleChange={handleSelectTag}
                      // Display all of the tags with a unique keyword in the dropdown (and add an untagged option at the start)
                      data={(() => {
                        const tagsListForFilter = [...tagsListForCombobox]
                        tagsListForFilter.unshift(
                          {
                            id: 'untagged',
                            name: 'Untagged only',
                            description: 'Only show files that are not tagged',
                          },
                          {
                            id: 'tagged',
                            name: 'Tagged only',
                            description: 'Only show files that are tagged',
                          }
                        )
                        return tagsListForFilter
                      })()}
                    />
                  )}
                </div>
                {tag != null && tagsList !== undefined && <FilterUndoButton filter="tag" handleUndo={handleUndo} />}
              </div>
              <div className="flex flex-row w-full">
                <div className="w-full">
                  <label htmlFor="datemodifiedrange" className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                    Date Modified Range
                  </label>
                  <Datepicker
                    value={datePickerValue}
                    onChange={handleSelectDateRange}
                    primaryColor={'indigo'}
                    containerClassName={'text-base font-base text-inherit relative'}
                    inputClassName={classnames(getInputStyles(), 'px-2  py-1.5 inline-block')}
                    popoverDirection="down"
                    displayFormat={'MM/DD/YYYY'}
                    showShortcuts={true}
                    maxDate={new Date()}
                  />
                </div>
                {datePickerValue.startDate !== null && datePickerValue.endDate && <FilterUndoButton filter="dateRange" handleUndo={handleUndo} />}
              </div>
            </div>
            {/* Commented out until duplicate directories in GDrive are figured out */}
            {/* <div className="grid grid-auto-flow grid-cols-3 gap-3">
            <div>
              <label htmlFor="directories" className="block text-sm font-medium text-slate-700">
                Directories
              </label>
              {directoriesList !== undefined && (
                <ComboboxAutocomplete
                  labelPlural="Directories"
                  value={parentFileId ?? 'All Directories'}
                  handleChange={handleSelectDirectory}
                  data={directoriesList?.map((opportunity) => ({ id: opportunity.id, name: opportunity.name }))}
                />
              )}
            </div>
          </div> */}
          </Panel>
          {isLoading && <p className="dark:text-white">Loading ...</p>}
          {!(files == null) && !isLoading && (
            <>
              <Table<File>
                colConfig={[
                  {
                    renderHeader: () => <TableCheckMark onClick={handleHeaderCheckMarkClick} id="header checkmark" checked={headerCheckStatus} />,
                    render: (rec) => (
                      <div className="z-40">
                        <TableCheckMark
                          onClick={() => handleCheckMarkClick(rec.fileId, rec.fileName)}
                          checked={!(filesChecked.get(rec.fileId) == null)}
                          id={`${rec.cuid} checkmark`}
                        />
                      </div>
                    ),
                  },
                  {
                    label: 'Type',
                    value: 'fileExtension',
                    render: (rec) => {
                      const iconStyles = fileExtensionIconStyles.find((style) => {
                        if (style.fileType === rec.fileExtension.toLowerCase()) return true
                        // else if (style.fileType === rec.fileMimeType.toLowerCase()) return true
                        else return false
                      })
                      return (
                        <div className="w-10">
                          {rec.fileMimeType === 'application/vnd.google-apps.document' && <GoogleDocIcon />}
                          {rec.fileMimeType === 'application/vnd.google-apps.presentation' && <GoogleSlidesIcon />}
                          {rec.fileMimeType === 'application/vnd.google-apps.spreadsheet' && <GoogleSheetIcon />}
                          {rec.fileMimeType === 'application/vnd.google-apps.form' && <GoogleFormIcon />}

                          {!Array.from(GOOGLE_DOCUMENTS_MIME_TYPES.keys()).includes(rec.fileMimeType) && (
                            <FileIconWithTypes
                              extension={rec.fileExtension}
                              color={iconStyles?.color ?? 'var(--file-icon-default)'}
                              labelColor={iconStyles?.labelColor ?? 'var(--file-icon-default)'}
                              glyphColor={iconStyles?.glyphColor ?? 'var(--file-icon-glyph)'}
                              type={iconStyles?.type}
                              foldColor={iconStyles?.color ?? 'var(--file-icon-default)'}
                            />
                          )}
                        </div>
                      )
                    },
                    isSortable: true,
                  },
                  {
                    label: 'Name / Directory',
                    value: 'fileName/filePath',
                    contentClassName: 'max-w-[300px] 2xl:max-w-[700px] block z-40 p-0',
                    render: (rec) => (
                      <>
                        {hasRenamingPermission() ? (
                          <InlineEditInput
                            name="fileName"
                            label="File name"
                            initialValue={rec.fileName}
                            handleSubmit={async (editedValue) => {
                              if (editedValue !== rec.fileName && editedValue !== '' && editedValue !== null) {
                                await handleRename(rec.fileId, editedValue)
                              }
                            }}
                            hideLabel
                            className="font-medium z-10 mb-2"
                            validationSchema={Yup.string()
                              .test('is-file', 'Please include a valid file extension', (value) => {
                                const extensionRegex = /(.*)(\.[0-9a-z]+)$/i
                                return value != null ? extensionRegex.test(value) : false
                              })
                              .test('single-tag', 'Only one tag allowed', (value) => {
                                const tagRegex = /^(\[[^\]]*\]\[[^\]]*\])/
                                return value != null ? !tagRegex.test(value) : true
                              })}
                          />
                        ) : (
                          <p className="max-w-[300px] 2xl:max-w-[700px] block overflow-hidden overflow-ellipsis whitespace-nowrap font-medium mb-2">
                            {rec.fileName}
                          </p>
                        )}
                        {/* <div className="mt-2"> */}
                        <Tooltip
                          content={rec.filePath}
                          placement="bottom"
                          arrow={false}
                          className="text-xs z-10 pointer-events-none select-none bg-slate-600 dark:bg-slate-950 border dark:border-0 dark:border-0"
                        >
                          <p className="max-w-[300px] 2xl:max-w-[700px] block overflow-hidden overflow-ellipsis whitespace-nowrap text-xs">{rec.filePath}</p>
                        </Tooltip>
                        {/* </div> */}
                      </>
                    ),
                    isSortable: true,
                  },
                  {
                    label: 'Size',
                    value: 'fileSize',
                    render: (rec) => formatFileSize(rec.fileSize),
                    isSortable: true,
                  },
                  {
                    label: 'Modified By',
                    value: 'lastModifiedName',
                    render: (rec) => rec.lastModifiedName.split('@')[0],
                    isSortable: true,
                  },
                  {
                    label: 'Modified Date',
                    value: 'lastModifiedDate',
                    render: (rec) => formatDateTime(rec.lastModifiedDate),
                    isSortable: true,
                  },
                  {
                    label: 'Tags',
                    value: 'tag',
                    render: (rec) => (
                      <div className="flex">
                        {isTaggedFile(rec.fileName) && (
                          <TagBadge
                            name={extractTag(rec.fileName)}
                            key={extractTag(rec.fileName)}
                            onClose={async () => {
                              await handleDeleteTag(new Map().set(rec.fileId, rec.fileName))
                            }}
                            tagDescription={tagsList?.find((tag) => tag.keyword === extractTag(rec.fileName))?.item}
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    label: 'Actions',
                    render: (rec) => (
                      <div className="flex">
                        {tagsList !== undefined && hasTaggingPermission() && (
                          <TagPopover
                            tagsList={tagsListForCombobox}
                            fileIdsAndNames={new Map().set(rec.fileId, rec.fileName)}
                            onAddTag={handleAddTag}
                            onDeleteTag={handleDeleteTag}
                            dropDownRight
                          />
                        )}
                        <ActionsPopover
                          gDriveFileExternalLink={`${GOOGLE_DRIVE_FILE_LINK_HEADER}${rec.fileId}`}
                          gDriveFolderExternalLink={`${GOOGLE_DRIVE_FOLDER_LINK_HEADER}${rec.parentFileId}`}
                          getGDriveOpportunityFolderId={getOpportunityFolderId}
                          opportunityId={rec.opportunity?.slice(rec.opportunity.lastIndexOf('-') + 1) ?? null}
                          GOOGLE_DRIVE_FOLDER_LINK_HEADER={GOOGLE_DRIVE_FOLDER_LINK_HEADER}
                          onDownload={async () => {
                            await handleDownload([
                              {
                                id: rec.fileId,
                                name: rec.fileName,
                                opportunity: rec.opportunity,
                                mimeType: rec.fileMimeType,
                                extension: rec.fileExtension,
                              },
                            ])
                          }}
                          label={hasTaggingPermission() ? 'More' : 'Actions'}
                        />
                      </div>
                    ),
                  },
                ]}
                data={files}
                handlePaginatorClick={handlePaginatorClick}
                onSort={handleSort}
                sortingStatus={sortingStatus}
              />
            </>
          )}
          {numRowsChecked >= 1 && tagsList !== undefined && (
            <BulkActions
              numRowsChecked={numRowsChecked}
              tagsList={tagsListForCombobox}
              fileIdsAndNames={filesChecked}
              onAddTag={handleAddTag}
              onDeleteTag={handleDeleteTag}
              onDownload={async () => {
                // Convert the filesChecked Map to an array of objects with id, name, and opportunity properties
                await handleDownload(
                  Array.from(filesChecked.entries()).map((file) => {
                    const fullFile = files?.results.find((rec) => rec.fileId === file[0])
                    return {
                      id: file[0],
                      name: file[1],
                      opportunity: fullFile?.opportunity,
                      mimeType: fullFile?.fileMimeType,
                      extension: fullFile?.fileExtension,
                    }
                  })
                )
              }}
            />
          )}
        </div>
      </div>
    </main>
  )
}

export default FileExplorer
