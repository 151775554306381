import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useEffect, useRef, useState } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import PanelNotes from './NotesPanel'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppSelector, useAppDispatch } from '../../../shared/redux/hooks'
import { updateNote, addNote, deleteNote, getNotes } from '../Notes.slice'
import Modal from '../../../components/Modal'
import { Note } from '../../../apis/types'

interface NotesProps {
  sourceEntity: string
  label?: string
}

const NotesDrawer: React.FC<NotesProps> = ({ sourceEntity, label }) => {
  const [open, setOpen] = useState(false)
  const [postNoteBody, setPostNoteBody] = useState<string>('')
  const [confirmModal, setConfirmModal] = useState<React.ReactElement | boolean>(false)
  const { user } = useAuth0()
  const { noteRequestPhase, sourceEntities } = useAppSelector((state) => state.notes)
  const dispatch = useAppDispatch()
  const panelRef = useRef<HTMLDivElement>(null)

  if (sourceEntity == null) {
    throw new Error('sourceEntity is required')
  }

  useEffect(() => {
    dispatch(getNotes({ sourceEntity }))
  }, [dispatch, sourceEntity])

  // Keyboard shortcut listener
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        event.key === 'n' &&
        !event.ctrlKey &&
        !event.metaKey &&
        !(event.target instanceof HTMLInputElement) &&
        !(event.target instanceof HTMLTextAreaElement)
      ) {
        event.preventDefault()
        event.stopPropagation()
        setOpen(true)
      }
    }

    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  const handleNoteUpdate = async (noteCuid: string, body: string, tags: string[]) => {
    await dispatch(updateNote({ noteCuid, body, tags }))
  }

  const handleNoteTogglePin = async (note: Note) => {
    await dispatch(updateNote({ noteCuid: note.cuid, isPinned: !note.isPinned }))
  }

  const handleNoteDelete = async (note: Note) => {
    try {
      await new Promise((resolve, reject) => {
        setConfirmModal(<Modal resolve={resolve} reject={reject} />)
      })
      setConfirmModal(false)
      await dispatch(deleteNote({ noteCuid: note.cuid }))
    } catch (e) {
      setConfirmModal(false)
    }
  }

  return (
    <>
      {confirmModal}
      <button
        onClick={() => setOpen(true)}
        title="Shortcut: n"
        className="absolute top-6 right-6 z-20 w-12 h-12 rounded-full bg-indigo-500 dark:bg-indigo-700 hover:bg-indigo-400 hover:dark:bg-indigo-600 p-2 flex items-center justify-center"
      >
        <PencilSquareIcon className="size-5 text-white dark:text-white" />
        {sourceEntities[sourceEntity]?.length > 0 && (
          <>
            <span className="absolute p-.5 top-8 right-8 h-6 w-6 min-h-6 min-w-6 flex items-center justify-center animate-ping-slow rounded-full bg-slate-400 dark:bg-slate-600 opacity-75"></span>
            <span className="absolute p-.5 top-8 right-8 h-6 w-6 min-h-6 min-w-6 flex items-center justify-center text-sm font-semibold bg-slate-600 dark:bg-slate-700 border border-slate-700 dark:border-slate-600 rounded-full text-white dark:text-white">
              {sourceEntities[sourceEntity]?.length}
            </span>
          </>
        )}
      </button>
      <Dialog open={open} onClose={setOpen} className="relative z-30">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-300 ease-in-out data-[closed]:translate-x-full sm:duration-300"
              >
                <div
                  ref={panelRef}
                  className="flex h-full flex-col overflow-y-scroll bg-slate-50 dark:bg-slate-800 shadow-xl shadow-slate-400 dark:shadow-slate-950"
                >
                  <div className="bg-indigo-500 dark:bg-indigo-800 px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="m-0 text-base font-semibold text-white dark:text-white">
                        {sourceEntity.split('|')?.[0]?.replace(/\b\w/g, (l) => l.toUpperCase())} Notes
                        {label && <div className="text-sm font-normal text-white dark:text-indigo-300 w-[345px] truncate">{label}</div>}
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          title="Shortcut key: Esc"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-transparent text-indigo-200 hover:text-white dark:text-indigo-200 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-white dark:focus:ring-slate-400"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 px-1 py-6 px-4 text-slate-900 dark:text-slate-100 text-sm">
                    <PanelNotes
                      noteInput={postNoteBody}
                      notes={sourceEntities[sourceEntity]}
                      noteRequestPhase={noteRequestPhase}
                      setNoteInput={setPostNoteBody}
                      onPost={async (sourceEntity, body) => {
                        await dispatch(addNote({ sourceEntity, body }))
                      }}
                      onUpdate={handleNoteUpdate}
                      onTogglePin={handleNoteTogglePin}
                      onDelete={handleNoteDelete}
                      currentUserId={user?.email ?? ''}
                      sourceEntity={sourceEntity}
                    />
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default NotesDrawer
